<template>
  <template v-if="!idNullOrEmpty">
    <SetLoader v-if="!iframeShow" />
    <iframe
      :src="this.iframeSrc"
      class="hidden w-100 h-100 mt-2"
      id="xtraReportingView"
      frameborder="0"
    ></iframe>
  </template>
</template>
<script>
import $ from "jquery";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  name: "Reporting",
  data() {
    return {
      idNullOrEmpty: true,
      iframeShow: false,
      iframeSrc: null,
    };
  },
  components: {},
  mounted() {
    this.getReportView();
  },
  methods: {
    getReportView() {
      this.idNullOrEmpty = String.isNullOrWhiteSpace(this.$route.params.id);
      this.iframeSrc = null;
      this.iframeShow = false;
      if (this.idNullOrEmpty) return;

      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);

      var self = this,
        model = {
          url: "/XtraReporting/Viewer/" + this.$route.params.id,
        };
      this.$root
        .createKeyForCurrentUser(model)
        .then((response) => {
          var result = response.data,
            token = result.data;
          if (!String.isNullOrWhiteSpace(token)) {
            this.iframeSrc = `${this.$root.getReportingWebSiteUrl()}/${
              this.$setXRMReportAuthorizeActionUrl
            }?key=${encodeURIComponent(token)}`;

            var iFrame = $("#xtraReportingView");
            iFrame.on("load", function () {
              setCurrentPageBreadcrumbs(
                self.$t(
                  "ReportingView",
                  {},
                  { locale: self.$store.state.activeLang }
                ),
                []
              );
              self.$root.changeDocumentTitle(
                self.$t(
                  "ReportingView",
                  {},
                  { locale: self.$store.state.activeLang }
                )
              );

              self.iframeShow = true;
              iFrame.removeClass("hidden");
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  watch: {
    "$route.params.id"() {
      if (this.$route.name === "Reporting") {
        this.getReportView();
      }
    },
  },
};
</script>
